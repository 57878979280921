@import 'vendor/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins';
@import 'variables';

.dataTable
{
    table
    {
        border: none;
        thead
        {
            th
            {
                @include gradient-vertical(lighten($azul, 7%), $azul);
                text-align: center;
                padding: 0;
                a, &.no-sort
                {
                    white-space: nowrap;
                    padding: 10px;
                    text-align: center;
                    text-decoration: none !important;
                    color: #fff !important;
                    height: 100%;
                }

                a
                {
                    display: block;
                    @include gradient-vertical(lighten($azul, 7%), $azul);
                    &[href]:hover, &[href]:active, &[href].sort 
                    {
                        @include gradient-vertical(lighten($azul, 14%), lighten($azul, 7%));
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    
    nav
    {
        text-align: center;
    }

    

}

p.nothing-found
{
    margin: 0 !important;
    padding: 15px;
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
}