.panel-toolbar
{

	.panel-heading
	{
		padding-left: 0;
    	padding-right: 0;
    	padding-bottom: 0;
    	padding-top: 15px;
	}
	
}