.fa-btn 
{
    margin-right: 6px;
}

a.logo
{
    background-image: url('/img/jvsistema_logo.png');
    background-repeat: no-repeat;
    background-position: center center;
    width: 90px;
    height: 51px;
}


.panel-heading
{
    background-color: $vermelho !important;
    color: #fff !important;
    font-weight: bold !important;
    a{
        color: #fff !important;
    }
    .panel-title
    {
        font-size: 1.3em;
        padding-top: 11px;
        padding-bottom: 11px;
    }
}

.modal
{
    overflow-y:auto !important
}

body
{
    padding-right: 0px !important
}

.modal-open
{
    overflow-y: auto;
}

.bootstrap-growl
{
  font-size: 1.5em;
  .close
  {
    position: absolute;
    top: 0px;
    right: 4px;
  }
}

.modal-loading
{
  .modal-content
  {
    padding: 20px;
    max-width: 150px;
    margin: 0 auto;
    text-align: center
  }
}

.typeahead li a {
  color: #000 !important;
}

.typeahead li a:hover, .typeahead li.active a {
  color: #fff !important;
}
